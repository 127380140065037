import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_3/sub_5/slide1';

import SubSlide1 from './slides/subSlide1';
import SubSlide2 from './slides/subSlide2';
import SubSlide3 from './slides/subSlide3';
import SubSlide4 from './slides/subSlide4';
import SubSlide5 from './slides/subSlide5';
import SubSlide6 from './slides/subSlide6';
import SubSlide7 from './slides/subSlide7';
import SubSlide8 from './slides/subSlide8';
import SubSlide9 from './slides/subSlide9';
import SubSlide10 from './slides/subSlide10';
import SubSlide11 from './slides/subSlide11';
import SubSlide12 from './slides/subSlide12';
import SubSlide13 from './slides/subSlide13';
import SubSlide14 from './slides/subSlide14';
import SubSlide15 from './slides/subSlide15';
import SubSlide16 from './slides/subSlide16';
import SubSlide17 from './slides/subSlide17';
import SubSlide18 from './slides/subSlide18';
import SubSlide19 from './slides/subSlide19';

const getRandom = () => {
  return Math.random();
}

const slides = [
  <SubSlide1 key={getRandom} />,
  <SubSlide2 key={getRandom} />,
  <SubSlide3 key={getRandom} />,
  <SubSlide4 key={getRandom} />,
  <SubSlide5 key={getRandom} />,
  <SubSlide6 key={getRandom} />,
  <SubSlide7 key={getRandom} />,
  <SubSlide8 key={getRandom} />,
  <SubSlide9 key={getRandom} />,
  <SubSlide10 key={getRandom} />,
  <SubSlide11 key={getRandom} />,
  <SubSlide12 key={getRandom} />,
  <SubSlide13 key={getRandom} />,
  <SubSlide14 key={getRandom} />,
  <SubSlide15 key={getRandom} />,
  <SubSlide16 key={getRandom} />,
  <SubSlide17 key={getRandom} />,
  <SubSlide18 key={getRandom} />,
  <SubSlide19 key={getRandom} />,
];


const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      mdx(
        frontmatter: {
          language: { eq: "UA" }
          title: { eq: "slide-3-5-1-mobile" }
        }
      ) {
        body
      }
      file(
        relativePath: { eq: "chapter_3/sub_5/mobile/3-5-1-bg__mobile.png" }
      ) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      timelineTitels: mdx(
        frontmatter: { language: { eq: "UA" }, title: { eq: "timelines" } }
      ) {
        exports {
          titleArray {
            index
            position
            text
          }
        }
      }
    }
  `);

  return <Template query={query} slides={slides} title="Частина V" buttonText="Дивіться хід війни" />;
};

export default Slide;
