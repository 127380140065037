import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/ua/chapter_3/sub_5/slide1';

import WrapperMobile from 'src/slides/mobile/ua/chapter_3/sub_5/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Хронологія польсько-більшовицької війни I Варшавська битва" description="Від боїв за Вільно до контрнаступу Будьонного - історія польсько-більшовицької війни." lang="ua" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="Хронологія польсько-більшовицької війни I Варшавська битва" description="Від боїв за Вільно до контрнаступу Будьонного - історія польсько-більшовицької війни." lang="ua" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
